<template>
  <div>
    <div class="heading">
      <h3>关于我们</h3>
      <p class="title">愿微心动成为全球企业值得信赖的营销伙伴</p>
      <div class="heading_c">
        <div>
          <h4>成都微心动科技有限公司</h4>
          <p>
            成都微心动科技有限公司成立于2015年，是中国的会员一体化营销云的领先者，助力企业数字化智慧赋能，实现企业增收。
          </p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_c">
        <div class="us">
          <div class="us_t">
            <img class="us_z" src="../../assets/images/w.png" alt="">
            <div>
              <p>我们相信什么</p>
              <p>HAT DO WE BELIEVE</p>
            </div>
          </div>
          <p class="us_c">
            我们相信，在数字化的今天，智能化的前夜，所有的企业都需要拥抱以云计算、大数据、人工智能为代表的数字技术，迎接下一波属于产业的黄金时代。更早获得并融合这些能力，也是中国接下来5年引领全球经济的重要支撑点。<br />
            同时我们也相信，“优化、预测、决策”这些和数智化相关的能力，在接下来的5年里，具备大规模落地企业的可行性，甚至应用到企业之外的诸多领域。<br />
            我们选择从营销端切入这些领域，过程中探索属于数字化时代的，有别于传统企业应用的全新之路。
          </p>
        </div>
        <div class="us_l">
          <div class="uss">
            <div class="us_t">
               <img class="us_z" src="../../assets/images/w.png" alt="">
              <div>
                <p>我们在做什么</p>
                <p>hat We Do</p>
              </div>
            </div>
            <p class="us_c">
              我们为企业营销提供数字化、自动化、智能化基础设施以及相关的专业服务，帮助企业高效运营海量用户，打造品牌特有的个性化用户体验，同时快速收获业务增长。目前，微心动已为超过500家知名企业提供企业级服务并获得最佳实践，并在酒店、长租式公寓和大中型餐饮等行业内形成大量客户成功案例。
            </p>
          </div>
          <div class="uss">
            <div class="us_t">
               <img class="us_z" src="../../assets/images/w.png" alt="">
              <div>
                <p>我们的目标是什么</p>
                <p>here We’re Going</p>
              </div>
            </div>
            <p class="us_c">
              深度洞察客户，帮助企业用最有效的方式获得客户并传递价值，提升客户体验，在此基础之上，探索数智化在各个领域内的有意义且有挑战的应用。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="core">
      <h4>我们的核心价值观</h4>
      <div class="core_c">
        <div v-for="(v, index) in corelist" :key="index">
          <span></span>
          <p>
            {{ v }}
          </p>
        </div>
      </div>
    </div>
    <div class="renew">
      <h5>发展历程</h5>
      <div class="renew_c">
        <div class="content_l">
          <img
            :class="renew == 1 ? 'img2' : 'img1'"
            :src="renew == 1 ? img1 : img2"
          />
          <img
            :class="renew == 2 ? 'img2' : 'img1'"
            :src="renew == 2 ? img1 : img2"
          />
          <img
            :class="renew == 3 ? 'img2' : 'img1'"
            :src="renew == 3 ? img1 : img2"
          />
          <img
            :class="renew == 4 ? 'img2' : 'img1'"
            :src="renew == 4 ? img1 : img2"
          />
          <img
            :class="renew == 5 ? 'img2' : 'img1'"
            :src="renew == 5 ? img1 : img2"
          />
          <img
            :class="renew == 6 ? 'img2' : 'img1'"
            :src="renew == 6 ? img1 : img2"
          />
          <img
            :class="renew == 7 ? 'img2' : 'img1'"
            :src="renew == 7 ? img1 : img2"
          />
          <img
            :class="renew == 8 ? 'img2' : 'img1'"
            :src="renew == 8 ? img1 : img2"
          />
        </div>
        <div class="content_r">
          <div @mousemove="onrenew(1)">
            <p :class="renew == 1 ? 'number' : 'number_w'">2015</p>
            <p :class="renew == 1 ? 'renew_t' : 'renew_w'">
              微心动科技有限公司成立
            </p>
          </div>
          <div @mousemove="onrenew(2)">
            <p :class="renew == 2 ? 'number' : 'number_w'">2016</p>
            <p :class="renew == 2 ? 'renew_t' : 'renew_w'">
              与国内著名pms商西软成为紧密合作伙伴
            </p>
          </div>
          <div @mousemove="onrenew(3)">
            <p :class="renew == 3 ? 'number' : 'number_w'">2017</p>
            <p :class="renew == 3 ? 'renew_t' : 'renew_w'">
              合作客户达300＋，业务范围覆盖中国西南地区80%以上
            </p>
          </div>
          <div @mousemove="onrenew(4)">
            <p :class="renew == 4 ? 'number' : 'number_w'">2018</p>
            <p :class="renew == 4 ? 'renew_t' : 'renew_w'">
              与国内著名pms商千里马成为紧密合作伙伴
            </p>
          </div>
          <div @mousemove="onrenew(5)">
            <p :class="renew == 5 ? 'number' : 'number_w'">2019</p>
            <p :class="renew == 5 ? 'renew_t' : 'renew_w'">
              与石基畅联成为长期合作伙伴
            </p>
          </div>
          <div @mousemove="onrenew(6)">
            <p :class="renew == 6 ? 'number' : 'number_w'">2020</p>
            <p :class="renew == 6 ? 'renew_t' : 'renew_w'">
              微心动PC端新功能首发更新
            </p>
          </div>
          <div @mousemove="onrenew(7)">
            <p :class="renew == 7 ? 'number' : 'number_w'">2021</p>
            <p :class="renew == 7 ? 'renew_t' : 'renew_w'">合作客户达600＋</p>
          </div>
          <div @mousemove="onrenew(8)">
            <p :class="renew == 8 ? 'number' : 'number_w'">2022</p>
            <p :class="renew == 8 ? 'renew_t' : 'renew_w'">
              公司搬迁至成都市高新区理想中心<br />
              与神州天立集团、四川供销科产业集团合作
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="certificate">
      <img class="dh" src="../../assets/images/dh.png" alt="" />
      <el-carousel
        :interval="4000"
        type="card"
        indicator-position="none"
        height="5rem"
        arrow="never"
      >
        <el-carousel-item v-for="(v, i) in certificate" :key="i">
          <img :src="v.imgurl" class="zz" />
          <h3>{{ v.name }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contact">
      <h5>联系我们</h5>
      <div class="contact_c">
        <p class="contact_s">
          <span>地址</span>
          四川省成都市成都市武侯区天益街38号理想中心1栋1306
        </p>
        <div class="contact_l">
          <div class="contact_z">
            <img src="../../assets/images/phone.png" alt="" />
            <p>
              咨询电话：<br />
              028-6270-5855
            </p>
          </div>
          <div class="contact_z">
            <img src="../../assets/images/mailbox.png" alt="" />

            <p>
              邮箱：<br />
              hr@sindon.cn
            </p>
          </div>
          <div class="contact_a">
            <img src="../../assets/images/rw.png" alt="" />
            <p>关注我们</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      corelist: [
        "客户导向：对客户充满热情，在承诺、结果方面打造个人品牌。",
        "专业：保持学习心态，工作中坚持自我提高和相互分享",
        "创新：对新的集市和市场充满自信并全力挑战不断创新",
        "团队协作：与同事、合作伙伴真诚相处鼎力合作",
      ],
      img1: require("../../assets/images/product_z.png"),
      img2: require("../../assets/images/product_w.png"),
      renew: 1,
      number: 2,
      certificate: [
        {
          name: "酒店订单管理系统-著作权",
          imgurl: require("../../assets/images/certificate01.jpeg"),
        },
        {
          name: "酒店客房预订系统-著作权",
          imgurl: require("../../assets/images/certificate02.jpeg"),
        },
        {
          name: "酒店会员管理系统-著作权",
          imgurl: require("../../assets/images/certificate03.jpeg"),
        },
        {
          name: "酒店会议管理系统-著作权",
          imgurl: require("../../assets/images/certificate04.jpeg"),
        },
        {
          name: "酒店客房自助点餐系统-著作权",
          imgurl: require("../../assets/images/certificate05.jpeg"),
        },
        {
          name: "酒店扫码点餐系统-著作权",
          imgurl: require("../../assets/images/certificate07.jpeg"),
        },
        {
          name: "酒店积分兑换系统-著作权",
          imgurl: require("../../assets/images/certificate08.jpeg"),
        },
        {
          name: "酒店商城产品售卖系统-著作权",
          imgurl: require("../../assets/images/certificate09.jpeg"),
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onrenew(i) {
      this.renew = i;
    },
    oncertificate(i) {
      this.number = i;
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Aboutus_bj.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  .title {
    width: 7.43rem;
    margin: 0.62rem auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
  .heading_c {
    position: absolute;
    bottom: -2rem;
    right: 2.4rem;
    display: flex;
    width: 14.4rem;
    height: 2.7rem;
    background-image: url(../../assets/images/us.png);
    background-size: 100% 100%;
    div {
      width: 8.72rem;
      height: 2.7rem;
      text-align: left;
      box-sizing: border-box;
      padding: 0.7rem 0.88rem;
      h4 {
        font-size: 0.28rem;
        padding-bottom: 0.16rem;
      }
      p {
        line-height: 0.3rem;
        font-size: 0.22rem;
      }
    }
  }
}
.content {
  padding-top: 3.08rem;
  .content_c {
    padding: 0 1.8rem;
    .us {
      width: 15.56rem;
      .us_t {
        padding-bottom: 0.2rem;
        position: relative;
        .us_z {
          position: absolute;
          top: -0.21rem;
          width: 0.64rem;
            height: 0.95rem;
        }
        div {
          padding-left: 0.7rem;
          p {
            font-size: 0.28rem;
            line-height: 0.3rem;
          }
          p:nth-child(2) {
            font-size: 0.16rem;
            color: #ffc53d;
          }
        }
      }
      .us_c {
        font-size: 0.18rem;
        line-height: 0.27rem;
        color: #333;
      }
    }
    .us_l {
      display: flex;
      justify-content: space-between;
      .uss {
        width: 7.62rem;
        padding-top: 0.46rem;
      .us_t {
        padding-bottom: 0.2rem;
        position: relative;
        .us_z {
          position: absolute;
          top: -0.21rem;
          width: 0.64rem;
            height: 0.95rem;
        }
        div {
          padding-left: 0.7rem;
          p {
            font-size: 0.28rem;
            line-height: 0.3rem;
          }
          p:nth-child(2) {
            font-size: 0.16rem;
            color: #ffc53d;
          }
        }
      }
        .us_c {
          font-size: 0.18rem;
          line-height: 0.27rem;
          color: #333;
        }
      }
    }
  }
}
.core {
  padding: 1rem 2.81rem 0.63rem;
  h4 {
    text-align: center;
    font-size: 0.28rem;
    color: #333;
  }
  .core_c {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 0.58rem;
    div {
      width: 6.65rem;
      height: 0.71rem;
      box-sizing: border-box;
      background-color: #323232;
      border-radius: 0.15rem;
      font-size: 0.18rem;
      line-height: 0.24rem;
      color: #fffff5;
      padding: 0.24rem 0 0.2rem 0.63rem;
      margin-bottom: 0.36rem;
      display: flex;
      span {
        display: inline-block;
        width: 0.08rem;
        height: 0.08rem;
        background-color: #ffc53d;
        border-radius: 50%;
        margin-right: 0.1rem;
        margin-top: 0.08rem;
      }
      p {
        width: 6rem;
      }
    }
  }
}
.renew {
  padding: 0 0 1.3rem;
  h5 {
    text-align: center;
    font-size: 0.28rem;
    padding-bottom: 0.58rem;
  }

  .renew_c {
    height: 7.2rem;
    margin: 0 4.18rem;
    background-image: url(../../assets/images/product_bj.png);
    background-size: 100% 100%;
    display: flex;
    .content_l {
      padding-top: 0.2rem;
      .img1 {
        display: block;
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.53rem;
        margin-bottom: 0.7rem;
      }
      .img2 {
        display: block;
        width: 0.36rem;
        height: 0.36rem;
        margin-left: 0.47rem;
        margin-bottom: 0.66rem;
      }
    }
    .content_r {
      padding-top: 0.05em;
      div {
        display: flex;
        padding-bottom: 0.18rem;
        .number {
          width: 1.58rem;
          height: 0.52rem;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #fff;
          border-radius: 0.08rem;
          text-align: center;
          background-color: #ffc53d;
          margin-left: 0.64rem;
          margin-top: 0.1rem;
        }
        .number_w {
          width: 1.58rem;
          height: 0.52rem;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #ffc53d;
          border-radius: 0.08rem;
          text-align: center;
          background-color: #ffedc3;
          margin-left: 0.64rem;
          margin-top: 0.1rem;
        }
        .renew_t {
          font-size: 0.18rem;
          width: 6.56rem;
          line-height: 0.27rem;
          background-color: #323232;
          color: #fff;
          border-radius: 0.08rem;
          margin-left: 0.95rem;
          box-sizing: border-box;
          padding: 0.24rem 0.48rem;
        }
        .renew_w {
          width: 6.56rem;
          font-size: 0.18rem;
          line-height: 0.27rem;
          background-color: #f2f2f2;
          border-radius: 0.08rem;
          margin-left: 0.95rem;
          box-sizing: border-box;
          padding: 0.24rem 0.48rem;
        }
      }
    }
  }
}
.certificate {
  width: 9.98rem;
  height: 3.96rem;
  padding: 0.92rem 0.18rem 0.92rem 2rem;
  box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
  margin: 0 auto;
  position: relative;
  text-align: center;

  .dh {
    position: absolute;
    top: -1.25rem;
    left: 0;
    width: 2.15rem;
  }
  .zz {
    width: 2.48rem;
    height: 3.6rem;
  }
}
.contact {
  color: #333;
  padding-bottom: 1.04rem;
  h5 {
    padding: 1.2rem 0 0.41rem;
    text-align: center;
    font-size: 0.28rem;
  }
  .contact_c {
    width: 14.4rem;
    height: 3.49rem;
    margin: 0 auto;
    padding-bottom: 0.3rem;
    background-color: #fafafa;
    .contact_s {
      padding: 0.65rem 0.68rem 0.3rem;
      font-size: 0.18rem;
      span {
        display: block;
        font-size: 0.22rem;
        font-weight: 600;
        padding-bottom: 0.18rem;
      }
    }
    .contact_l {
      width: 14rem;
      margin: 0 auto;
      box-sizing: border-box;
      height: 1.75rem;
      // box-shadow: 0px 3px 6px 1px rgba(247, 198, 89, 0.2);
      background-color: #fff;
      display: flex;
      .contact_z {
        display: flex;
        padding: 0.47rem 2.3rem 0.47rem 0.48rem;
        img {
          width: 0.8rem;
          height: 0.8rem;
        }
        p {
          padding-left: 0.2rem;
          font-size: 0.22rem;
          line-height: 0.4rem;
        }
      }
      .contact_a {
        color: #333;
        padding: 0.2rem 1.2rem 0.13rem 0.48rem;
        text-align: center;
        img {
          width: 1rem;
          height: 1rem;
        }
        p {
          font-size: 0.18rem;
        }
      }
    }
  }
}
.el-carousel__container {
  width: 8rem;
  height: 5rem !important;
}
.el-carousel__item h3 {
  color: #666;
  font-size: 0.16rem;
}

.el-carousel__item {
  width: 3rem;
  height: 4rem;
  background-color: #fff;
}
</style>
